import _ from "underscore";
import { Categories, CategoryLabels } from "../data/partners.js";
import Content from "./Content.js";
import PartnerCategoriesFilter from "./PartnerCategoriesFilter.js";
import PartnerPopup from "./PartnerPopup.js";
import React from "react";
import Section from "./Section.js";
import { useStore } from "./store/useStore.js";
import I18n from "./I18n.js";

const Partner = props => {
    return (
        <li
            className="col-4-12 col-tablet-1-2 col-phablet-1-1 ae-2"
            onClick={props.handleSelect}
        >
            <div className="sc">
                <div className="sc-img">
                    <img
                        alt={props.partner.name}
                        src={"/static/img/partners/" + props.partner.logo}
                    />
                </div>
                <strong>{props.partner.name}</strong>
            </div>
        </li>
    );
};

const getCurrentPartnerName = (partners, currentPartnerName) => {
    if (currentPartnerName) {
        return _.find(partners, partner => partner.name === currentPartnerName);
    }
};

const getPartnerIndex = (partners, currentPartnerName) => {
    let index = null;
    _.find(partners, (partner, idx) => {
        if (partner.name === currentPartnerName) {
            index = idx;
            return true;
        }
    });
    return index;
};

const getNextPartnerName = (partners, currentPartnerName) => {
    const index = getPartnerIndex(partners, currentPartnerName);
    if (_.isNumber(index) && index < _.size(partners) - 1) {
        return partners[index + 1].name;
    }
};

const getPreviousPartnerName = (partners, currentPartnerName) => {
    const index = getPartnerIndex(partners, currentPartnerName);
    if (_.isNumber(index) && index > 0) {
        return partners[index - 1].name;
    }
};

const getPartners = ({ partners, selectedCategories }) => {
    if (_.isEmpty(selectedCategories)) {
        return partners;
    }
    return _.filter(partners, partner => {
        const diff = _.difference(selectedCategories, partner.categories);
        return _.isEmpty(diff);
    });
};

const Partners = props => {
    const [currentPartnerName, setCurrentPartnerName] = React.useState(null);
    const [selectedCategories, setSelectedCategories] = React.useState([]);
    const [, setNavigationHidden] = useStore("navigationHidden", false);
    const partners = getPartners({
        partners: props.partners,
        selectedCategories
    });
    const currentPartner = getCurrentPartnerName(partners, currentPartnerName);
    const partnersByCategory = _.groupBy(partners, partner =>
        _.first(partner.categories)
    );
    return (
        <Section
            sectionProps={{
                className: "slide whiteSlide member"
            }}
        >
            <Content>
                <div className="container">
                    <div className="wrap padding-left-0 padding-right-0">
                        <div
                            style={{
                                paddingLeft: "50px",
                                paddingRight: "50px",
                                marginBottom: "20px"
                            }}
                        >
                            <PartnerCategoriesFilter
                                partners={partners}
                                selectedCategories={selectedCategories}
                                toggleCategory={category => {
                                    if (
                                        _.contains(selectedCategories, category)
                                    ) {
                                        setSelectedCategories([]);
                                    } else {
                                        setSelectedCategories([category]);
                                    }
                                }}
                            />
                        </div>
                        {_.map(Categories, category => {
                            const categoryPartners =
                                partnersByCategory[category];
                            if (_.isEmpty(categoryPartners)) return null;
                            return (
                                <div className="fix-custom" key={category}>
                                    <h1 className="ae-1 fromLeft header-11 light">
                                        <I18n
                                            label={`Partner_${CategoryLabels[category]}`}
                                        />
                                    </h1>
                                    <ul
                                        className="flex member-container popupTrigger controller fromBottom"
                                        data-popup-id="member"
                                        data-slider-id="member"
                                    >
                                        {_.map(categoryPartners, partner => {
                                            return (
                                                <Partner
                                                    key={partner.name}
                                                    partner={partner}
                                                    handleSelect={() => {
                                                        setCurrentPartnerName(
                                                            partner.name
                                                        );
                                                        setNavigationHidden(
                                                            true
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Content>
            {currentPartner && (
                <PartnerPopup
                    handleClose={() => {
                        setCurrentPartnerName(null);
                        setNavigationHidden(false);
                    }}
                    handleNext={() => {
                        const name = getNextPartnerName(
                            partners,
                            currentPartnerName
                        );
                        if (name) {
                            setCurrentPartnerName(name);
                        }
                    }}
                    handlePrevious={() => {
                        const name = getPreviousPartnerName(
                            partners,
                            currentPartnerName
                        );
                        if (name) {
                            setCurrentPartnerName(name);
                        }
                    }}
                    partner={currentPartner}
                />
            )}
        </Section>
    );
};

export default Partners;
