import _ from "underscore";
import Close from "./svg/Close.js";
import React from "react";
import I18n from "./I18n.js";
import { CategoryLabels } from "../data/partners.js";

const PartnerDetails = props => {
    return (
        <div>
            <div className="fix-12-12">
                <div className="fix-8-12 toLeft">
                    <a href={props.partner.url} rel="nofollow" target="_blank">
                        <span className="h1 big ae-1">
                            {props.partner.name}
                        </span>
                    </a>
                    <div className="ae-3 margin-top-2">
                        {!_.isEmpty(props.partner.description) &&
                            props.partner.description.map((text, index) => {
                                return (
                                    <p key={index} className="small">
                                        <I18n label={text} />
                                    </p>
                                );
                            })}
                        {!_.isEmpty(props.partner.categories) && (
                            <div
                                style={{
                                    fontSize: "18px",
                                    margin: "30px 0 40px 0"
                                }}
                            >
                                {props.partner.categories.map(category => (
                                    <div key={category}>
                                        #{" "}
                                        <I18n
                                            label={`Partner_${CategoryLabels[category]}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {props.partner.url && (
                        <a
                            className="button white round stroke small ae-4"
                            href={props.partner.url}
                            rel="nofollow"
                            target="_blank"
                        >
                            <I18n label="VisitWebsite" />
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

const PartnerPopup = props => {
    return (
        <div
            className="popup animated member visible active"
            data-popup-id="member"
            style={{ zIndex: 100 }}
        >
            <div className="close" onClick={props.handleClose}>
                <Close />
            </div>
            <div className="content">
                <div className="container">
                    <div className="wrap left top padding-top-4 padding-bottom-4 padding-left-3 padding-right-3">
                        <div className="fix-custom-popup popupContent">
                            <div className="fix-12-12">
                                <div
                                    className="button noSelect round small white opaque cropLeft"
                                    data-slider-action="prev"
                                    data-slider-id="member"
                                    onClick={props.handlePrevious}
                                >
                                    <i className="material-icons">arrow_back</i>
                                    <I18n label="Previous" />
                                </div>
                                <div
                                    className="button noSelect round small white opaque margin-left-1"
                                    data-slider-action="next"
                                    data-slider-id="member"
                                    onClick={props.handleNext}
                                >
                                    <I18n label="Next" />
                                    <i className="material-icons right">
                                        arrow_forward
                                    </i>
                                </div>
                            </div>
                            <ul
                                className="slider margin-top-4"
                                data-slider-id="member"
                            >
                                <li className="selected">
                                    <PartnerDetails partner={props.partner} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerPopup;
