import _ from "underscore";
import { Categories, CategoryLabels } from "../data/partners.js";
import React from "react";
import { translate } from "./I18n.js";
import LocaleContext from "./LocaleContext.js";

const getColor = category => {
    if (category.disabled) {
        return "#aaa";
    }
    return category.selected ? "#fff" : "#000";
};

const getBackgroundColor = category => {
    if (category.disabled) {
        return "#fff";
    }
    return category.selected ? "#ec6674" : "#eee";
};

const Category = ({ category, toggleCategory }) => {
    const style = {
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 0,
        margin: "5px",
        padding: "10px 20px",
        color: getColor(category),
        backgroundColor: getBackgroundColor(category),
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };
    return (
        <div
            style={style}
            onClick={() => {
                if (!category.disabled) {
                    toggleCategory(category.value);
                }
            }}
        >
            {category.label}
        </div>
    );
};

const PartnerCategoriesFilter = props => {
    const { locale } = React.useContext(LocaleContext);
    const categories = _.map(Categories, category => {
        return {
            label: translate({
                label: `Partner_${CategoryLabels[category]}`,
                locale
            }),
            disabled: false,
            selected: _.contains(props.selectedCategories, category),
            value: category
        };
    });
    return (
        <div
            style={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "space-around",
                flexWrap: "wrap"
            }}
        >
            {_.map(categories, category => {
                return (
                    <Category
                        key={category.value}
                        category={category}
                        toggleCategory={props.toggleCategory}
                    />
                );
            })}
        </div>
    );
};

export default PartnerCategoriesFilter;
