import MainLayout from "../components/MainLayout.js";
import { partners } from "../data/partners.js";
import Nav from "../components/Nav.js";
import React from "react";
import LocaleProvider from "../components/LocaleProvider.js";
import Partners from "../components/PartnerOrgs.js";

const PartnersPage = props => {
    return (
        <LocaleProvider>
            <MainLayout>
                <Nav darkMenu={true} />
                <Partners partners={partners} />
            </MainLayout>
        </LocaleProvider>
    );
};

export default PartnersPage;
